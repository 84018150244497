import React from "react"
import { Range, getTrackBackground } from "react-range"

import styles from "./Range.module.css"

const RangeView = ({ min = 100, max = 5000, values, handleChange }) => {
  return (
    <>
      <div className={styles.titleWrapp}>
        <p>I&apos;D LIKE TO BORROW:</p>
        <p>{`${values[0]}$`}</p>
      </div>
      <div className={styles.sliderWrapp}>
        <p>Drag the slider below to the amount you&apos;d like to borrow</p>
        <Range
          step={100}
          min={min}
          max={max}
          values={values}
          onChange={handleChange}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: "36px",
                display: "flex",
                width: "100%",
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: "5px",
                  width: "100%",
                  borderRadius: "4px",
                  background: getTrackBackground({
                    values: values,
                    colors: ["#0074B5", "#ccc"],
                    min: min,
                    max: max,
                  }),
                  alignSelf: "center",
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "20px",
                width: "20px",
                backgroundColor: "#0074B5",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <div
                style={{
                  height: "8px",
                  width: "8px",
                  backgroundColor: "#fff",
                  borderRadius: "1px",
                }}
              ></div>
            </div>
          )}
        />
        <div>
          <p>100$</p>
          <p>5000$</p>
        </div>
      </div>
    </>
  )
}

export default RangeView

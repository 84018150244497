import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { scroller as scroll } from "react-scroll"

import Form from "./Form"
import styles from "./HomePage.module.css"
import startedImg from "../../assets/images/1.png"
import connectedImg from "../../assets/images/2.png"
import moneyImg from "../../assets/images/3.png"

const HomePageView = ({ location }) => {
  useEffect(() => {
    if (location.state && location.state.howItWorks) {
      scroll.scrollTo("howItWorks", {
        duration: 400,
        delay: 100,
        smooth: true,
      })
    }
    if (location.state && location.state.aboutUs) {
      scroll.scrollTo("aboutUs", {
        duration: 400,
        delay: 100,
        smooth: true,
        offset: -70,
      })
    }
  }, [location])

  return (
    <>
      <section className={styles.container}>
        <div className={styles.formCont}>
          <Form />
        </div>
      </section>
      <section id="aboutUs" className={styles.section}>
        <h2>About Us</h2>
        <p>
          Handicaploan.com offers an easy way to get money keeping your
          security. This is a loan for everything from home repairs to rental
          payments, from car repairs to other bills and expenses. We understand
          that not everyone has excellent credit. The truth is that the majority
          of Americans have low enough scores that it is either difficult or
          impossible for them to find funding from a bank or credit union. That
          doesn’t sit right with us. Our loan connection service is free and
          connecting you with one of trusted third party lenders and lending
          partners within a several minutes to get you up to $5,000.To get the
          process started you need to fill out our secure online application.
        </p>
      </section>
      <section className={styles.section}>
        <div className={styles.factsWrapp}>
          <div className={styles.factsCont}>
            <div className={styles.facts}>
              <div className={styles.factWrap}>
                <span className={styles.count}>65K</span>
                <span className={styles.title}>Loans Taken</span>
              </div>
            </div>
            <div className={styles.facts}>
              <div className={styles.factWrap}>
                <span className={styles.count}>18</span>
                <span className={styles.title}>Offices</span>
              </div>
            </div>
            <div className={styles.facts}>
              <div className={styles.factWrap}>
                <span className={styles.count}>100%</span>
                <span className={styles.title}>Happy Clients</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="howItWorks" className={styles.section}>
        <h2>How It Works</h2>
        <div className={styles.row}>
          <div className={styles.card}>
            <img src={startedImg} alt="1" />
            <h3 className={styles.cardTitle}>Complete the form</h3>
            <div className={styles.cardContent}>
              <p>
                Take a few minutes to fill out our simple loan request form for
                instant review. Don’t worry about your information being safe;
                our website is secured by the latest technology.
              </p>
            </div>
          </div>
          <div className={styles.card}>
            <img src={connectedImg} alt="2" />
            <h3 className={styles.cardTitle}>Verification</h3>
            <div className={styles.cardContent}>
              <p>
                After we connect you with a third party lender or lending
                partner, you are redirected to their website. They’ll review
                your answers to calculate the amount of funds you will receive.
              </p>
            </div>
          </div>
          <div className={styles.card}>
            <img src={moneyImg} alt="3" />
            <h3 className={styles.cardTitle}>Get your money</h3>
            <div className={styles.cardContent}>
              <p>
                Once you agree to the lender’s terms and are approved for a
                loan, your money will be deposited into your personal bank
                account, usually as soon as the next business day.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

HomePageView.propTypes = {
  location: PropTypes.object.isRequired,
}

export default HomePageView

import React, { useState, useCallback } from "react"
import { Link } from "gatsby"
import * as pathnames from "../../../constants/pathnames"

import Button from "../../UI/Button"
import Input from "../../UI/Input"
import Range from "../../UI/Range"

import styles from "./Form.module.css"

const FormView = () => {
  const [range, setRange] = useState([2500])

  const handleRangeChange = useCallback(values => {
    setRange(values)
  }, [])

  return (
    <div className={styles.wrapper}>
      <Range handleChange={handleRangeChange} values={range} />
      <div className={styles.inputWrapp}>
        <div className={styles.inputRow}>
          <Input placeholder="First Name" />
          <Input placeholder="Last Name" />
        </div>
        <div className={styles.inputRow}>
          <Input placeholder="Email" />
          <Input placeholder="ZIP" />
        </div>
      </div>
      <Button>GET STARTED</Button>
      <div className={styles.agree}>
        By clicking &apos;Get Started&apos;, I consent and agree to the
        {"  "}
        <Link to={pathnames.eConsent}>E-Consent,</Link>
        {"  "}
        <Link to={pathnames.privacyPolicy}>Privacy Policy,</Link>
        {"  "}
        <Link to={pathnames.termsConditions}>Terms & Conditions,</Link>
        {"  "}
        <Link to={pathnames.privacyPolicy}>
          Responsible Lending & Marketing,
        </Link>
        {" and "}
        <Link to={pathnames.legalDisclaimer}>Legal Disclaimers</Link>
      </div>
    </div>
  )
}

export default FormView
